<template>
  <div
    ref="message-alert-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="delalert"
  >
    <div class="modal-dialog modal-helper" :class="`modal-${modalSize}`">
      <div class="modal-content">
        <div class="modal-title">{{ msgTitle }}</div>
        <div class="helper-content" v-html="msgContent"></div>
        <div class="modal-btn-block">
          <div class="modal-btn" data-dismiss="modal" @click="confirm">
            {{ confirmText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    msgTitle: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    msgContent: {
      type: String,
      required: false,
      default() {
        return '';
      }
    },
    confirmText: {
      type: String,
      required: false,
      default() {
        return '確定';
      }
    },
    modalSize: {
      type: String,
      required: false,
      default() {
        return 'xs';
      }
    }
  },
  watch: {
    show: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue) {
          $(this.$refs['message-alert-modal']).modal('toggle');
        }
      }
    }
  },
  methods: {
    confirm() {
      this.$emit('close-modal');
    }
  }
};
</script>
